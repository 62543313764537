import { useGlobalStore } from "@/store/global"

export default defineNuxtRouteMiddleware(async (to, from) => {

    const store = useGlobalStore()

    let defaultTranslation = store.settings.preferredReadingTranslation
    
    /**
     *  Tento Middleware je zavolaný len v index.vue Page
     *  a teda len pri URL "/", ktorú presmeruje na čitateľské
     *  prostredie s preferovaným prekladom užívateľa, ktorý sa už
     *  nachádza v cookies údajoch (tie sú načítané v plugins/data.server.ts)
     * 
     */
    return navigateTo("/citanie/" + defaultTranslation + "/mk/1")
        
})